import firebase from "firebase/compat/app"; // TO FIX Attempted import error: 'firebase/app' does not contain a defau
import "firebase/compat/firestore";
//import * as firebase from "firebase";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA1VlAKJywbvkLpsY5nfTT3VgpkXdCvBLg",
  authDomain: "my-portfolio-38276.firebaseapp.com",
  projectId: "my-portfolio-38276",
  storageBucket: "my-portfolio-38276.appspot.com",
  messagingSenderId: "1091958284781",
  appId: "1:1091958284781:web:2668038e420579d1a787e4",
  measurementId: "G-8PXMT462D8",
});

var db = firebaseApp.firestore();
// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export { db };
